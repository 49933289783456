function step($element) {
    return function (now) {
        $element.text(Math.ceil(now));
    };
}

$(function () {
    var sexes = ['men', 'women', 'children', 'pets', 'total'];
    for (var i in sexes) {
        var $element = $('#' + sexes[i]);
        if ($element.length) {
            (function ($element) {
                new Waypoint({
                    element: $element,
                    handler: function (direction) {
                        this.destroy();
                        $element.prop('counter', 0).animate({
                            counter: $element.text(),
                        }, {
                            duration: 3000,
                            easing: 'swing',
                            step: step($element),
                        });
                    },
                    offset: '100%',
                });
            })($element);
        }
    }
});
