window.jQuery = window.$ = require('jquery');
var select2 = require('select2/dist/js/select2.full.js');
if (typeof select2 === 'function') {
    select2(window, jQuery)
}
require('jquery-ui');
require('waypoints/lib/jquery.waypoints.js');

require('bootstrap3-sass');

require('./myjquery');
require('./counter');

$(document).ready(function () {
    $('.file-upload').change(function (e) {
        for (var i = 0; i < e.originalEvent.srcElement.files.length; i++) {
            var count = this.id.substring('profilePhoto'.length);

            var file = e.originalEvent.srcElement.files[i];

            var reader = new FileReader();
            reader.onloadend = function () {
                $('#photoImg' + count).prop('src', reader.result);
            };
            reader.readAsDataURL(file);
        }
    });
});

$(document).ready(function() {
    var ids = '#id_country, #id_nationality, #id_lives_in_country, #id_lives_in_city, #id_height, #id_weight, #id_bust, #id_waist, #id_hips, #id_shoe_size';
    $(ids).each(function() {
        $(this).select2($(this).hasClass('empty-field') ? { containerCssClass: 'empty-field' } : {});
    });
});
